import {
  HelpFooterBlockContainer,
  HelpFooterBlockHeader,
  HelpFooterBlockHeaderIcon,
  HelpFooterBlockHeaderText,
  HelpFooterBlockLink,
  HelpFooterBlockParagraph,
} from './HelpFooterBlock.style'
import help from '../../img/icon-ui-help-outline-24-px.svg'
import { Divider } from '../Divider/Divider'

type Props = {
  ariaLabelSuffix?: string
}

export const HelpFooterBlock = ({ ariaLabelSuffix }: Props) => {
  const title = 'Still need help?'
  const ariaLabelHeader = ariaLabelSuffix ? `${title}. ${ariaLabelSuffix}` : title

  return (
    <HelpFooterBlockContainer>
      <HelpFooterBlockHeader>
        <HelpFooterBlockHeaderIcon aria-hidden src={help} />
        <HelpFooterBlockHeaderText role="text" aria-label={ariaLabelHeader}>
          {title}
        </HelpFooterBlockHeaderText>
      </HelpFooterBlockHeader>
      <Divider />
      <HelpFooterBlockParagraph>
        For more assistance go to our{' '}
        <HelpFooterBlockLink target={'_blank'} href={'https://trains.virgin.com/hc/en-gb/sections/360005239198-Ticket-refunds-and-changes'}>
          Support Hub
        </HelpFooterBlockLink>
        .
      </HelpFooterBlockParagraph>
    </HelpFooterBlockContainer>
  )
}
